export default {
  id: {
    name: "id",
    type: "where",
    value: "",
  },
  edrpou: {
    name: "usreou",
    type: "where",
    value: "",
  },
  fullName: {
    name: "fullName",
    type: "like",
    value: "",
  },
  dateFrom: {
    name: "created",
    type: "gte",
    value: "",
  },
  dateTo: {
    name: "created",
    type: "lte",
    value: "",
  },
  identificationStatus: {
    name: "identificationStatus",
    type: "whereIn",
    value: [],
  },
  verificationMethod: {
    name: "verificationMethod",
    type: "whereIn",
    value: [],
  },
  currentCrimeRiskLevel: {
    name: "currentCrimeRiskLevel",
    type: "whereIn",
    value: [],
  },
  identFrom: {
    name: "identificationDate",
    type: "gte",
    value: "",
  },
  identTo: {
    name: "identificationDate",
    type: "lte",
    value: "",
  },
  contract: {
    name: "contractType",
    type: "where",
    value: "",
  },
  counterpartType: {
    name: "type",
    type: "whereIn",
    value: [],
  },
  isPublic: {
    name: "isPublic",
    type: "where",
    value: "",
  },
  withDeleted: {
    name: "deleted",
    type: "with",
    value: "",
  },
  destinationCode: {
    name: "destinationCode",
    type: "where",
    value: "",
  },
  site: {
    name: "site",
    type: "like",
    value: "",
  },
  financialModel: {
    name: "financialModel",
    type: "whereIn",
    value: [],
  },
};
