<template>
  <ValidationObserver ref="formFilterValidation" v-slot="{ handleSubmit }">
    <div v-if="Object.keys(errors).length > 0">
      <b-alert v-if="errors.code === 406" show variant="danger">
        <h4 class="alert-heading">Виникла помилка при пошуку контрагентів!</h4>
        <li v-for="(error, index) in errors.description" :key="index">
          <span v-for="(e, i) in error" :key="i"> {{ e }} </span>
        </li>
      </b-alert>

      <b-alert v-else show variant="danger">
        <h4 class="alert-heading">
          Виникла невідома помилка роботи серверу, спробуйте оновити сторінку
        </h4>
        <li>{{ errors.code }} - {{ errors.description }}</li>
      </b-alert>
    </div>

    <b-form>
      <b-form-row>
        <b-col md="6">
          <ValidationProvider
            name="id"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="filter.id@where"
            :rules="{
              regex: $stringConstants('REGEX_UUID'),
            }"
          >
            <b-form-group
              label="ID контрагента"
              label-cols="12"
              label-cols-lg="3"
            >
              <b-form-input
                v-model="filter.id.value"
                placeholder=""
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <b-col md="6">
          <ValidationProvider
            name="назва"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="filter.fullName@like"
            :rules="{
              max: 255,
            }"
          >
            <b-form-group
              label="Пошук по назві контрагента"
              label-cols="12"
              label-cols-lg="3"
            >
              <b-form-input
                v-model="filter.fullName.value"
                placeholder=""
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col md="6">
          <ValidationProvider
            name="ЄДРПОУ/РНОКПП контрагента"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="filter.usreou@where"
            :rules="{
              max: 255,
            }"
          >
            <b-form-group
              label="ЄДРПОУ/РНОКПП контрагента"
              label-cols="12"
              label-cols-lg="3"
            >
              <b-form-input
                v-model="filter.edrpou.value"
                placeholder=""
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <b-col md="6">
          <ValidationProvider
            name="тип контрагента"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="filter.type@whereIn"
          >
            <b-form-group
              label="Тип контрагента"
              label-cols="12"
              label-cols-lg="3"
            >
              <multiselect
                v-model="filter.counterpartType.value"
                :options="counterpartTypes.map((e) => e.id)"
                :multiple="true"
                :custom-label="customCounterpartTypeLabel"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :show-labels="false"
                placeholder=""
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              >
                <template class="checkbox-label" v-slot:option="scope">
                  <input
                    type="checkbox"
                    :checked="
                      filter.counterpartType.value.includes(scope.option)
                    "
                    @focus.prevent
                  />
                  {{ customCounterpartTypeLabel(scope.option) }}
                </template>
              </multiselect>
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col md="6">
          <ValidationProvider
            name="рівень ризику"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="filter.currentCrimeRiskLevel@whereIn"
          >
            <b-form-group
              label="Рівень ризику"
              label-cols="12"
              label-cols-lg="3"
            >
              <multiselect
                v-model="filter.currentCrimeRiskLevel.value"
                :options="crimeRiskLevels.map((e) => e.id)"
                :multiple="true"
                :close-on-select="false"
                :custom-label="customCrimeRiskLevelLabel"
                :clear-on-select="false"
                :preserve-search="true"
                :show-labels="false"
                placeholder=""
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              >
                <template class="checkbox-label" v-slot:option="scope">
                  <input
                    type="checkbox"
                    :checked="
                      filter.currentCrimeRiskLevel.value.includes(scope.option)
                    "
                    @focus.prevent
                  />
                  {{ customCrimeRiskLevelLabel(scope.option) }}
                </template>
              </multiselect>
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col md="6">
          <ValidationProvider
            name="статус ідентифікації"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="filter.identificationStatus@whereIn"
          >
            <b-form-group
              label="Статус ідентифікації"
              label-cols="12"
              label-cols-lg="3"
            >
              <multiselect
                v-model="filter.identificationStatus.value"
                :options="identificationStatuses.map((e) => e.id)"
                :multiple="true"
                :custom-label="customIdentificationStatusLabel"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :show-labels="false"
                placeholder=""
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              >
                <template class="checkbox-label" v-slot:option="scope">
                  <input
                    type="checkbox"
                    :checked="
                      filter.identificationStatus.value.includes(scope.option)
                    "
                    @focus.prevent
                  />
                  {{ customIdentificationStatusLabel(scope.option) }}
                </template>
              </multiselect>
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col md="6">
          <b-form-group
            label="Дата створення"
            label-cols="12"
            label-cols-lg="3"
          >
            <b-input-group>
              <b-col sm="6" class="p-0 pr-2">
                <ValidationProvider
                  name="початок періоду"
                  v-slot="{ errors, dirty, validated, valid, changed }"
                  vid="filter.created@gte"
                >
                  <b-form-input
                    type="date"
                    v-model="filter.dateFrom.value"
                    :state="
                      setValidationState(
                        errors,
                        dirty,
                        validated,
                        valid,
                        changed
                      )
                    "
                  />
                  <b-form-text>Початок періоду</b-form-text>
                  <b-form-invalid-feedback
                    :state="errors.length === 0"
                    v-for="(error, index) in errors"
                    v-bind:key="index"
                  >
                    {{ error }}
                  </b-form-invalid-feedback>
                </ValidationProvider>
              </b-col>

              <b-col sm="6" class="p-0 pl-2">
                <ValidationProvider
                  name="кінець періоду"
                  v-slot="{ errors, dirty, validated, valid, changed }"
                  vid="filter.created@lte"
                >
                  <b-form-input
                    type="date"
                    v-model="filter.dateTo.value"
                    :state="
                      setValidationState(
                        errors,
                        dirty,
                        validated,
                        valid,
                        changed
                      )
                    "
                  />
                  <b-form-text>Кінець періоду</b-form-text>
                  <b-form-invalid-feedback
                    :state="errors.length === 0"
                    v-for="(error, index) in errors"
                    v-bind:key="index"
                  >
                    {{ error }}
                  </b-form-invalid-feedback>
                </ValidationProvider>
              </b-col>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Дата ідентифікації"
            label-cols="12"
            label-cols-lg="3"
          >
            <b-input-group>
              <b-col sm="6" class="p-0 pr-2">
                <ValidationProvider
                  name="початок періоду"
                  v-slot="{ errors, dirty, validated, valid, changed }"
                  vid="filter.identificationDate@gte"
                >
                  <b-form-input
                    type="date"
                    v-model="filter.identFrom.value"
                    :state="
                      setValidationState(
                        errors,
                        dirty,
                        validated,
                        valid,
                        changed
                      )
                    "
                  />
                  <b-form-text>Початок періоду</b-form-text>
                  <b-form-invalid-feedback
                    :state="errors.length === 0"
                    v-for="(error, index) in errors"
                    v-bind:key="index"
                  >
                    {{ error }}
                  </b-form-invalid-feedback>
                </ValidationProvider>
              </b-col>

              <b-col sm="6" class="p-0 pl-2">
                <ValidationProvider
                  name="кінець періоду"
                  v-slot="{ errors, dirty, validated, valid, changed }"
                  vid="filter.identificationDate@lte"
                >
                  <b-form-input
                    type="date"
                    v-model="filter.identTo.value"
                    :state="
                      setValidationState(
                        errors,
                        dirty,
                        validated,
                        valid,
                        changed
                      )
                    "
                  />
                  <b-form-text>Кінець періоду</b-form-text>
                  <b-form-invalid-feedback
                    :state="errors.length === 0"
                    v-for="(error, index) in errors"
                    v-bind:key="index"
                  >
                    {{ error }}
                  </b-form-invalid-feedback>
                </ValidationProvider>
              </b-col>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col md="6">
          <ValidationProvider
            name="тип договору"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="filter.contractType@where"
          >
            <b-form-group
              label="Тип договору"
              label-cols="12"
              label-cols-lg="3"
            >
              <b-form-select
                v-model="filter.contract.value"
                :options="contractTypes"
                text-field="name"
                value-field="value"
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              >
                <template #first>
                  <b-form-select-option value=""></b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <b-col md="6">
          <ValidationProvider
            name="належність до публічних осіб, до осіб, близьких або пов'язаних з публічними особами"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="filter.isPublic@where"
          >
            <b-form-group
              label="Належність до публічних осіб, до осіб, близьких або пов'язаних з публічними особами"
              label-cols="12"
              label-cols-lg="3"
            >
              <b-form-select
                v-model="filter.isPublic.value"
                :options="isPublicTypes"
                text-field="name"
                value-field="value"
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              >
                <template #first>
                  <b-form-select-option value=""></b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col md="6">
          <ValidationProvider
            name="код призначення платежу/виплати"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="filter.destinationCode@where"
          >
            <b-form-group
              label="Код призначення платежу/виплати"
              label-cols="12"
              label-cols-lg="3"
            >
              <multiselect
                :value="
                  operationDestinationCodeList.find(
                    (o) => o.id === filter.destinationCode.value
                  )
                "
                @input="
                  (value) =>
                    (filter.destinationCode.value = value ? value.id : '')
                "
                :custom-label="customDestinationCodeLabel"
                track-by="id"
                :options="operationDestinationCodeList"
                placeholder=""
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              >
                <template
                  class="operation-destination-code-label"
                  v-slot:option="scope"
                >
                  <img
                    v-if="scope.option.ended_at"
                    src="/images/text-help.svg"
                    alt="help"
                    style="margin-right: 10px"
                    v-b-tooltip
                    :title="tooltipDestinationCode(scope.option)"
                  />
                  <template>
                    {{ customDestinationCodeLabel(scope.option) }}
                  </template>
                </template>
              </multiselect>
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <b-col md="6">
          <ValidationProvider
            name="профіль СПФМ"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="filter.financialModel@whereIn"
          >
            <b-form-group
              label="Профіль СПФМ"
              label-cols="12"
              label-cols-lg="3"
            >
              <multiselect
                v-model="filter.financialModel.value"
                :options="meFinancialModels.map((e) => e.id)"
                :multiple="true"
                :custom-label="customFinancialModelLabel"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :show-labels="false"
                placeholder=""
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              >
                <template class="checkbox-label" v-slot:option="scope">
                  <input
                    type="checkbox"
                    :checked="
                      filter.financialModel.value.includes(scope.option)
                    "
                    @focus.prevent
                  />
                  {{ customFinancialModelLabel(scope.option) }}
                </template>
              </multiselect>
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col md="6">
          <ValidationProvider
            name="веб-сайт"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="filter.site@like"
            :rules="{
              max: 255,
            }"
          >
            <b-form-group label="Веб-сайт" label-cols="3" label-cols-lg="3">
              <b-form-input
                v-model="filter.site.value"
                placeholder=""
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col md="6">
          <ValidationProvider
            name="спосіб проведення верифікації"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="filter.verificationMethod@whereIn"
          >
            <b-form-group
              label="Спосіб проведення верифікації"
              label-cols="12"
              label-cols-lg="3"
            >
              <multiselect
                v-model="filter.verificationMethod.value"
                :options="verificationMethods.map((e) => e.id)"
                :multiple="true"
                :custom-label="customVerificationMethodLabel"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :show-labels="false"
                placeholder=""
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              >
                <template class="checkbox-label" v-slot:option="scope">
                  <input
                    type="checkbox"
                    :checked="
                      filter.verificationMethod.value.includes(scope.option)
                    "
                    @focus.prevent
                  />
                  {{ customVerificationMethodLabel(scope.option) }}
                </template>
              </multiselect>
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col md="6">
          <ValidationProvider
            name="видалені"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="filter.deleted@with"
          >
            <b-form-group
              label="Показати видалені"
              label-cols="12"
              label-cols-lg="3"
            >
              <b-form-checkbox
                name="check-button"
                switch
                size="lg"
                v-model="filter.withDeleted.value"
                value="1"
                unchecked-value="0"
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-form-row>
    </b-form>
  </ValidationObserver>
</template>

<script>
import filterList from "./../structure/filter";
import debounce from "debounce";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import queryFilter from "@/shared/filterQuery";
import qs from "qs";
import mixins from "@/mixins";

export default {
  name: "filter-form",
  components: { Multiselect },
  mixins: [mixins],
  data() {
    return {
      errors: {},
      filter: filterList,
      isPublicTypes: [
        {
          name: "Так",
          value: 1,
        },
        {
          name: "Ні",
          value: 0,
        },
      ],
    };
  },
  created() {
    this.debouncedFilter = debounce(this.filterSet, 500);
  },
  beforeDestroy() {
    Object.keys(this.filter).forEach((key) => {
      this.filter[key].value = "";
    });
  },
  mounted() {
    const query = Object.assign({}, this.$route.query);

    if (
      Object.keys(query).length &&
      Object.keys(query).some((key) => this.filter.hasOwnProperty(key))
    ) {
      this.$store.commit(
        "counterparts/setFilter",
        queryFilter.methods.getFilterFromQuery(query, this.filter)
      );
    } else {
      this.debouncedFilter();
    }
  },
  computed: {
    ...mapGetters({
      crimeRiskLevels: "dictionary/allCounterpartCrimeRiskLevels",
      contractTypes: "dictionary/allContractTypes",
      identificationStatuses: "dictionary/allCounterpartIdentificationStatuses",
      counterpartTypes: "dictionary/allCounterpartTypes",
      operationDestinationCodeList: "dictionary/allOperationDestinationCode",
      meFinancialModels: "auth/meFinancialModels",
      verificationMethods: "dictionary/allCounterpartVerificationMethods",
    }),
  },
  watch: {
    filter: {
      deep: true,
      handler(filter) {
        const newQuery = queryFilter.methods.getQueryFromFilter({}, filter);
        if (
          qs.stringify(this.$route.query) !==
          qs.stringify(newQuery, { arrayFormat: "comma" })
        ) {
          this.$router.replace({ query: newQuery });
        }

        this.$nextTick(() => {
          this.$refs.formFilterValidation.validate().then((success) => {
            if (!success) {
              this.$snotify.warning("Вказано невірний формат поля");
              return;
            }
            this.debouncedFilter();
          });
        });
      },
    },
  },
  methods: {
    filterSet() {
      this.errors = {};
      this.$store.commit("counterparts/setFilter", this.filter);
      this.$store
        .dispatch("counterparts/setCounterparts")
        .catch(({ response }) => {
          this.$set(this, "errors", response.data);

          if (response.status === 406) {
            this.$refs.formFilterValidation.setErrors(this.errors.description);
          }
        });
    },
    customDestinationCodeLabel({ name, value }) {
      return `(${value}) ${name}`;
    },
    customFinancialModelLabel(id) {
      return this.meFinancialModels.find((e) => e.id === id).name;
    },
    customCrimeRiskLevelLabel(id) {
      return this.crimeRiskLevels.find((e) => e.id === id).name;
    },
    customIdentificationStatusLabel(id) {
      return this.identificationStatuses.find((e) => e.id === id).name;
    },
    customVerificationMethodLabel(id) {
      return this.verificationMethods.find((e) => e.id === id).name;
    },
    customCounterpartTypeLabel(id) {
      return this.counterpartTypes.find((e) => e.id === id).name;
    },
    tooltipDestinationCode($code) {
      return "Активний по " + $code.ended_at;
    },
  },
};
</script>
